@charset "UTF-8";

@import "../core/variables";

$color_3: #555;
$font-family_1: "Inter", "Noto Sans JP", sans-serif;

/* Adjust TOP
********************/
body {
  line-height: 1.6;
  font-family: $font-family_1 !important;
  font-weight: 400;
  letter-spacing: 0.03em;
  font-size: 14px;
  color: $color_3;
  background: #fff;

  a{
    color: #555;
  }
  #wrapper {
    margin: 0 auto !important;
    max-width: none !important;
  }
}

/* GROUND TOP
********************/
a[href]:hover, a[onclick]:hover {
  text-decoration:underline;
  /* text-decoration:underline; */
  opacity:1; /* Standard: FF gt 1.5, Opera, Safari */
  -ms-filter:"alpha(opacity=100)"; /* IE 8 */
  filter:alpha(opacity=100); /* IE lt 8 */
  -khtml-opacity:1; /* Safari 1.x */
  -moz-opacity:1; /* FF lt 1.5, Netscape */
}

/* clearfix */
.clearfix:after { content: "."; display:block; height:0; clear:both; visibility:hidden; }
.clearfix { display: inline-table; min-height: 1%; }
* html .clearfix { height: 1%; }
.clearfix { display:block; }

/* hide_text */
.hide_text { text-indent: 100%; white-space: nowrap; overflow: hidden; }

/* CONTENT */
#feature_box{
  float: left;
  width: 720px;
  margin-right: 10px;
  margin-bottom: 30px;
}
#side_box{
  float: left;
  width: 230px;
}
#section_exp{
  margin-bottom: 40px;
  xxpadding-bottom: 40px;
  xxborder-bottom: 1px solid #ebebeb;
}
.link_tolist p{
  text-align: right;
  margin: 15px 10px 0 0;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
}
.link_tolist p a{
  color: #4dac26;
}
.link_tolist p a b{
  font-weight: bold;
}

/* boxのサイズ調整 */
#news_all, #cue, .cue_order, .indiv, .indiv_rank_num .other, .indiv_point, .swiper-pagination-bullet{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

/* ヘッダー画像 */
.top_index_header {
  width: 100%;
  background: #fff;
  padding-top: 80px;
  margin-bottom: 32px;
}
.top_index_header__inner {
  width: auto;
  margin: 0 auto;
  max-width: 640px;
  padding: 16px 16px 24px;
  text-align: center;
}

.top_index_header__textImg {
  margin-bottom: 30px;
}

.top_index_header__title {
  margin-bottom: 108px;
}

.top_index_header__text {
  font-size: 15px;
  line-height: 1.8;
  text-align: center;
  margin: 0 auto;
  color: $primary-color;
}

.top_index_header__btn {
  display: block;
  margin: 16px 16px 24px;
}

.top_index_header__inner .top_index_header__btn {
  display: block;
  margin: 16px 0 24px;
}

.top_index_header__btn .mc_btnH40 {
  display: block;
  width: auto;
  max-width: 320px;
  margin: 0 auto;
  height: 56px;
  line-height: 56px;
}

/* NEWS */
#news_all{
  border-radius: 4px;
  border: 1px solid #dbae3f;
  margin-bottom: 14px;
  position: relative;
  -webkit-font-smoothing: antialiased;
  background: #fccb4e;
}
#news_all .news_mark{
  position: absolute;
  width: 12px;
  height: 12px;
  font-weight: bold;
  background: $caution-color;
  color: #fff;
  border-radius: 12px;
  top: -4px;
  left: -4px;
}
#news_all .news_mark span{
  position: absolute;
  top: -3px;
  left: 5px;
}
.blinking {
  -webkit-animation: blink 1.5s infinite alternate;
  -moz-animation: blink 1.5s infinite alternate;
  animation: blink 1.5s infinite alternate;
}
@-webkit-keyframes blink {
  from { opacity: 1.0; }
  to { opacity: 0; }
}
@-moz-keyframes blink {
  from { opacity: 1.0; }
  to { opacity: 0; }
}
@keyframes blink {
  from { opacity: 1.0; }
  to { opacity: 0; }
}
#news_all .news_col_left{
  float: left;
  width: 34px;
  background: #fccb4e;
  line-height: 1;
  padding: 8px 0 7px 4px;
  color: #84620d;
  font-weight: bold;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
#news_all .news_col_right{
  float: left;
  width: 662px;
  padding: 2px 0 0 6px;
  border: 1px solid #fccb4e;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background: #fff;
}
#news_all p span.news_date{
  font-size: 10px;
  margin-right: 5px;
}
#news_all p span.news_title{
  font-size: 14px;
}
#news_all p a{
  color: #4dac26;
  font-weight: bold;
}
@media (max-width: 480px) {
  #news_all {
    max-width: calc(100% - 24px);
    margin: 0 auto;
  }
  .news_subject {
    max-width: 256px;
  }
}

/* 応募状況 */
#cue{
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #fccb4e;
  width: 710px;
  border: 1px solid #dbae3f;
  margin-bottom: 36px;
  padding: 3px;
}
#cue .textimg{
  float: left;
  width: 35px;
  height: 90px;
  background: url(/assets/images/cue_textimg.png) no-repeat 8px 18px;
}
#cue .cue_order{
  float: left;
  width: 666px;
  background: #fff;
  border: 1px solid #dbae3f;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
#news_ticker{
  font-size: 12px;
  margin: 0 6px;
}
#news_ticker li{
  height: 30px;
  line-height: 30px;
  border-bottom: 1px dotted #e5e5e5;
  -webkit-font-smoothing: antialiased;
}
#news_ticker li span{
  padding-right: 10px;
  font-size: 10px;
}
#news_ticker li a{
  color: #4dac26;
  font-weight: bold;
}
#news_ticker li a:hover{
  text-decoration: underline;
}

/* イベントリストタイトル */
/*.itemlist_maintitle p{*/
/*  font-size: 18px;*/
/*  line-height: 1;*/
/*  margin-bottom: 12px;*/
/*}*/

/* たいけんフィルタ */
#section_exp{
  position: relative;
}

.title_area{
  display: inline-block;
}
.exp_filter_area{
  display: inline-block;
  font-size: 12px;
  position: relative;
}
.exp_filter_area p a{
  color: #666;
}
.exp_filter_area p span{
  display: inline-block;
  -webkit-font-smoothing: antialiased;
}
.exp_filter_area p span#now_event, .exp_filter_area p span#now_event span[class^="icon_"], .exp_filter_area p span#now_event i[class^="fa"]{
  margin-right: 4px;
}
.exp_filter_area p span.exp_num{
  font-size: 10px;
}
.exp_more{
  position: relative;
}
.more_arrow{
  position: absolute;
  border-color: #909090 transparent;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  width: 0;
  height: 0;
  top: 50%;
  margin-left: 3px;
  margin-top: -1px;
}
.exp_more a:hover{
  color: #333;
  border-color: #333 transparent;
  cursor: pointer;
}
.exp_more a span.more_arrow:hover{
  border-color: #333 transparent;
}
.exp_more_menu{
  -webkit-font-smoothing: antialiased;
  display: none;
  position: absolute;
  text-align: left;
  top: 23px;
  left: 50%;
  margin-left: -150px;
  background: #fff;
  z-index: 200;
  border: 1px solid #d6d6d6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 2px 4px #d6d6d6;
  width: 302px;
}

.exp_more_menu ul li{
  float: left;
  min-width: 150px;
}
.exp_more_menu ul li:nth-child(odd){
  border-right: 1px solid #e9e9e9;
}
.exp_more_menu ul li b{
  font-size: 12px;
  padding: 5px 10px;
  display: block;
}
.exp_more_menu ul li b:hover{
  text-decoration: none;
  background: #fffceb;
  cursor: pointer;
}
.exp_more_menu ul li span.exp_num{
  font-size: 10px;
  padding-left: 3px;
}
.exp_more_menu p.menu_selectall {
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #e9e9e9;
  line-height: 2.6;
}
.exp_more_menu p.menu_selectall b{
  display: block;
  min-width: 302px;
}
.exp_more_menu .menu_selectall:hover{
  background: #fffceb;
  cursor: pointer;
}

/* filter text adjust */
/*.exp_more_menu .icon_monitor{padding: 0 8px 0 5px;font-size: 13px;}*/
/*.exp_more_menu .icon_subject{padding: 0 6px 0 2px;font-size: 11px;}*/
/*.exp_more_menu .icon_goout{padding: 0 8px 0 4px;font-size: 12px;}*/
/*.exp_more_menu .icon_shopping{padding: 0 10px 0 4px;font-size: 13px;}*/
/*.exp_more_menu .icon_question{padding: 0 10px 0 4px;font-size: 13px;}*/
/*.exp_more_menu .icon_join{padding: 0 9px 0 2px;font-size: 13px;}*/
/*.exp_more_menu .icon_vote{padding: 0 8px 0 2px;font-size: 11px;}*/
/*.exp_more_menu .icon_photo{padding: 0 10px 0 2px;font-size: 10px;}*/
/*.exp_more_menu .icon_lot{padding: 0 10px 0 0px;font-size: 15px;}*/
/*.exp_more_menu .icon_click{padding: 0 10px 0 2px;font-size: 12px;}*/
/*.exp_more_menu .icon_reduce{padding: 0 9px 0 2px;font-size: 14px;}*/
/*.exp_more_menu .icon_point{padding: 0 10px 0 2px;font-size: 13px;}*/

/*#now_event .icon_monitor{color: $success-color;font-size: 13px;}*/
/*#now_event .icon_subject{color: #a36900;font-size: 11px;}*/
/*#now_event .icon_goout{color: #ef457a;font-size: 12px;}*/
/*#now_event .icon_shopping{color: #5db090;font-size: 13px;}*/
/*#now_event .icon_question{color: #d57900;font-size: 13px;}*/
/*#now_event .icon_join{color: #ff84c7;font-size: 13px;}*/
/*#now_event .icon_vote{color: #f0b001;font-size: 11px;}*/
/*#now_event .icon_photo{color: #696969;font-size: 10px;}*/
/*#now_event .icon_lot{color: #e95504;font-size: 15px;}*/
/*#now_event .icon_click{color: #2aa2cb;font-size: 12px;}*/
/*#now_event .icon_reduce{color: #5db090;font-size: 14px;}*/
/*#now_event .icon_point{color: #ba8d26;font-size: 13px;}*/

/*!* event type text color *!*/
/*.exp_more_menu .more_monitor b:hover{color: $success-color;}*/
/*.exp_more_menu .more_subject b:hover{color: #a36900;}*/
/*.exp_more_menu .more_goout b:hover{color: #ef457a;}*/
/*.exp_more_menu .more_shopping b:hover{color: #5db090;}*/
/*.exp_more_menu .more_question b:hover{color: #d57900;}*/
/*.exp_more_menu .more_join b:hover{color: #ff84c7;}*/
/*.exp_more_menu .more_vote b:hover{color: #f0b001;}*/
/*.exp_more_menu .more_photo b:hover{color: #696969;}*/
/*.exp_more_menu .more_lot b:hover{color: #e95504;}*/
/*.exp_more_menu .more_click b:hover{color: #2aa2cb;}*/
/*.exp_more_menu .more_reduce b:hover{color: #5db090;}*/
/*.exp_more_menu .more_point b:hover{color: #ba8d26;}*/

/* 応募ボタン
********************/
.hover_obj {
  position:relative;
}
.submit_overlay {
  display:none;
  z-index:5;
  position:absolute;
  width:100%;
  height:100%;
}
.hover_submit .submit_overlay {
  bottom:-76px;
  height:76px;
  background: #fffceb;
  z-index: 120;
}
a.submit_overlay:hover{
  text-decoration: none;
}
.submit_overlay .btn_step{
  width: 90%;
  padding: 6px 0;
  margin: 20px auto 0 auto;
  text-align: center;
  font-size: 16px;
  background: #6fb910;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 3px 0 #5e9d0e;
  border-radius: 5px;
  -webkit-font-smoothing: antialiased;
}
.submit_overlay .btn_step.disable{
  background: #ddd;
  color: #666;
  border: 1px solid #bbb;
  box-shadow: none;
  font-weight: normal;
}
.submit_overlay .btn_step:active{
  background: #5e9d0e;
}
.hover_obj a{
  display: block;
  z-index: 100;
  cursor: pointer;
  text-decoration: none;
}
.hover_obj a:hover{
  opacity: .8;
  text-decoration: none;
}

/* 個別イベント
********************/
.indiv{
  float: left;
  width: 32%;
  height: 350px;
  margin: 0 .5% 1% .5%;
  background: #fff;
  border: 1px solid $base-color-border;
}
.type_kind{
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}

/* event type head-border color */
/*.type_monitor{border-top: 3px solid $success-color;}*/
/*.type_goout{border-top: 3px solid #ef457a;}*/
/*.type_subject{border-top: 3px solid #a36900;}*/
/*.type_shopping{border-top: 3px solid #5db090;}*/
/*.type_question{border-top: 3px solid #d57900;}*/
/*.type_join{border-top: 3px solid #ff84c7;}*/
/*.type_vote{border-top: 3px solid #f0b001;}*/
/*.type_photo{border-top: 3px solid #696969;}*/
/*.type_lot{border-top: 3px solid #e95504;}*/
/*.type_click{border-top: 3px solid #2aa2cb;}*/
/*.type_reduce{border-top: 3px solid #5db090;}*/
/*.type_point{border-top: 3px solid #ba8d26;}*/

/*!* event type BG icon color *!*/
/*.icon_type_monitor{background: $success-color;}*/
/*.icon_type_goout{background: #ef457a;}*/
/*.icon_type_subject{background: #a36900;}*/
/*.icon_type_shopping{background: #5db090;}*/
/*.icon_type_question{background: #d57900;}*/
/*.icon_type_join{background: #ff84c7;}*/
/*.icon_type_vote{background: #f0b001;}*/
/*.icon_type_photo{background: #696969;}*/
/*.icon_type_lot{background: #e95504;}*/
/*.icon_type_click{background: #2aa2cb;}*/
/*.icon_type_reduce{background: #5db090;}*/
/*.icon_reduce{font-size: 12px;}*/
/*.icon_type_point{background: #ba8d26;}*/

/* 画像サイズ */
.eventimg{
  padding: 8px;
}


/* 情報 */
.info{
  padding: 0 10px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.cap_type, .title_text{
  margin-bottom: 12px;
}

.cap p{
  font-size: 14px;
  font-weight: bold;
  color: $caution-color;
}
.cap p span{
  font-size: 10px;
  color: #666;
  font-weight: normal;
}
.icon_type_text{padding-left: 4px;font-family: sans-serif!important;}

/* タイトルテキスト */
.title_text p{
  font-size: 13px;
  line-height: 1.4;
  height: 36px;
}

/* client name, SNS icon */
.cname{
  float: left;
  width: 130px;
  padding-top: 4px;
}
.icon_sns{
  float: right;
  font-size: 16px;
}
.cname p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon_feed, .fa-rss-square{color: #b64342;}
.icon_twitter, .fa-twitter-square{color: #55acee;}
.icon_facebook, .fa-facebook-square{color: #3b5998;}
.icon_instagram, .fa-instagram{color: #666;}
.mark_wchance{
  display: inline-block;
  font-size: 10px;
  background: $base-color-border;
  line-height: 1;
  padding: 2px 4px;
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  -webkit-font-smoothing: antialiased;
}
.mark_submit_done{
  position: absolute;
  top: 8px;
  left: 0;
  font-size: 10px;
  background: #222;
  color: #fff;
  border: 1px solid #fff;
  border-left: none;
  z-index: 100;
  line-height: 1;
  padding: 3px;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-font-smoothing: antialiased;
}
.mc_pager.top_exp{
  margin-top: 20px;
  -webkit-font-smoothing: antialiased;
}
/* ポイントイベント
********************/
.itemlist_point{
  -webkit-font-smoothing: antialiased;
}
.itemlist_point .list{
  display: none;
}
.itemlist_point .list.selected {
  display: block;
}
.itemlist_point #point_tab {
  margin-top: 10px;
  margin-bottom: 18px;
  text-align: left;
}
.itemlist_point #point_tab li {
  display: inline;
}
.itemlist_point #point_tab li span{
  font-size: 10px;
  padding-left: 2px;
}
.itemlist_point #point_tab li a:hover{
  text-decoration: none;
}
.itemlist_point #point_tab .tab {
  display: inline-block;
  padding: 1px;
  min-width: 14%;
  white-space: nowrap;
  text-align: center;
  color: #999;
  font-size: 12px;
  border-bottom: 2px solid #ecdbb4;
}
.itemlist_point #point_tab .tab.selected {
  border-bottom: 2px solid #ba8d26;
  color: #604403;
}

/* ポイントイベント */
.swiper-container{
  width: 710px;
}
.swiper-wrapper{
  width: 650px;
  margin: 0 auto;
  height: 236px;
}
.swiper-button-next, .swiper-button-next.swiper-button-disabled, .swiper-button-prev, .swiper-button-prev.swiper-button-disabled{
  height: 34px;
  background-image: none;
  font-size: 30px;
  color: #ba8d26;
  line-height: 1;
}
.swiper-button-next, .swiper-button-next.swiper-button-disabled{
  -webkit-transform:rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.swiper-button-prev, .swiper-button-prev.swiper-button-disabled{
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.swiper-button-next{right: 0;}
.swiper-button-prev{left: 0;}
.swiper-pagination{
  width: 710px;
  margin: 10px auto 0 auto;
}
.swiper-pagination span{
  margin: 0 3px;
}
.swiper-pagination-bullet{
  background: #fff;
  opacity: 1;
  border: 1px solid #aaa;
}
.swiper-pagination-bullet-active{
  background: #ba8d26;
  border: 1px solid #ba8d26;
}
.indiv_point{
  width: 140px;
  height: 200px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  background: #fff;
}
.indiv_point:hover{
  border: 1px solid #4dac26;
  background: #fffceb;
}
.indiv_point a:hover{
  text-decoration: none;
}
.eventimg_point img{
  width: 120px;
  height: 120px;
}
.point_num p{
  font-size: 18px;
  font-weight: bold;
  color: $caution-color;
  text-align: center;
  line-height: 1;
  padding: 8px 0;
  -webkit-font-smoothing: antialiased;
}
.point_num p span{
  font-size: 13px;
  padding-left: 1px;
  font-weight: normal;
}
.title_text_point{
  font-size: 12px;
  line-height: 1.3;
}





/* SIDE BOX
********************/
/* AD, user summary */
.top #banner, .top #userSummary{
  margin-bottom: 20px;
}

/* バナー */
.bnr_area ul{margin-bottom: 20px;}
.bnr_area ul li{margin-bottom: 10px;}

/* ランキング, 〆切間近 */
#ranking_area{
  margin-bottom: 26px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}
#ranking_area ol li, #close_area ol li{
  margin-bottom: 10px;
}
.side_item_img{
  float: left;
  width: 70px;
  border: 1px solid #e9e9e9;
  margin-right: 6px;
}
.side_item_info{
  float: left;
  width: 150px;
  -webkit-font-smoothing: antialiased;
}
.raking_num{
  float: left;
  width: 20px;
  margin-right: 4px;
}
.cue_num{
  float: left;
  width: 130px;
}
.side_item_img img{
  width: 70px;
  height: 70px;
}
.indiv_rank_num .raking_num{
  width: 16px;
  height: 16px;
  color: #fff;
  position: relative;
  font-weight: bold;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.indiv_rank_num .one{background: #dab432;}
.indiv_rank_num .one span{padding-left: 5px;position: absolute;top: -1px;}
.indiv_rank_num .two{background: #6f7b83;}
.indiv_rank_num .two span{padding-left: 5px;position: absolute;top: -1px;}
.indiv_rank_num .three{background: #a3531f;}
.indiv_rank_num .three span{padding-left: 5px;position: absolute;top: -1px;}
.indiv_rank_num .other{background: #fff;border: 1px solid #ccc;}
.indiv_rank_num .other span{padding-left: 4px;color: #8a8a8a;position: absolute;top: -2px;}

.cue_num p, .close_num p{
  font-size: 18px;
  font-weight: bold;
  color: $caution-color;
  line-height: 1;
}
.cue_num p span, .close_num p span{
  font-size: 10px;
  color: #666;
  font-weight: normal;
}
.indiv_title p{
  margin-top: 10px;
  line-height: 1.3;
  font-size: 13px;
}


@media (max-width: 690px) {
  .indiv{
    float: none;
    width: 100%;
    height: auto;
    margin: 0;
    border: 0;
    border-top: 1px solid $base-color-border;
    padding: 8px;
  }

  .type_kind{
    display: flex;
    align-items: center;
  }

  .eventimg{
    margin: 0;
    width: 160px;
    height: 160px;
  }

  .info{
    flex-grow: 3;
  }

  .title_text p{
    font-size: 16px;
    line-height: 1.4;
    height: 42px;
  }
}

@media (max-width: 480px) {
  .eventimg{
    margin: 0;
    width: 100px;
    height: 100px;
  }
  .top_index_header__btn .mc_btnH40 {
    max-width: 480px;
  }
}

